<template>
  <div class="container">
    <div class="banner">
        <div class="w solution-bg">
            <h3>科技服务</h3>
            <p>客户的需求就是我们不断努力的方向。公司本着“以人为本、客户至上“的原则<br />通过不断的学习创新，发扬工匠精神，努力为客户提供极致的产品和最优的服务</p>
            <button @click="showKefuBox">在线咨询&nbsp;<i class="icon-im"></i></button>
        </div>
    </div>
    <!--//产品分类-->
    <div class="product">
        <div class="w">
            <h3 class="t">企业服务理念</h3>
            <br />
            <ul>
                <li class="show"><span><i class="icon iconfont">&#xe6de;</i></span><h3>高企认定</h3><p>提升企业的品牌与产品价值</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe6f5;</i></span><h3>软著申请</h3><p>企业视觉识别系统</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe70a;</i></span><h3>商标申请</h3><p>每一张出品都显向高端品质</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe735;</i></span><h3>专利申请</h3><p>源自于大自然无穷的创意体现</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe828;</i></span><h3>9001认证</h3><p>包装设计价值,让世界看见您的才华</p><div class="show_bg">&nbsp;</div></li>
            </ul>
        </div>
    </div>
    <div class="product-item">
        <div class="w">
            <ul class="show">
                <li><div class="img"><img src="../../assets/shuanruanrenzhen.png" /></div><h3>双软评估</h3></li>
                <li><div class="img"><img src="../../assets/zijinfuchi.png" /></div><h3>资金扶持</h3></li>
                <li><div class="img"><img src="../../assets/zizhirenzhen.png" /></div><h3>资质认证</h3></li>
                <li><div class="img"><img src="../../assets/kexinxinyanjiubaogao.png" /></div><h3>项目可研报告</h3></li>
                <li><div class="img"><img src="../../assets/shangyejihuashu.png" /></div><h3>商业计划书</h3></li>
            </ul>
            <div class="clearfix"></div>
        </div>
    </div>
    <!--//开发服务流程-->
    <div class="develop-index">
        <div class="w">
            <h3 class="t">开发服务流程</h3>
            <div class="d">优质服务本该如此，先进项目管理流程，运筹帷幄，决胜千里</div>
            <p><img src="../../assets/process.png" /></p>
        </div>
    </div>
    <!--//与更多优秀企业一起告诉成长-->
    <div class="ads-index">
      <h3>与更多优秀企业一起高速成长</h3>
      <p>通过领先的技术和严谨的管理，赢得客户的好评，并结成战略合作关系</p>
    </div>
    <!--//客户案例-->
    <div class="clients-index"><img src="../../assets/clients.png" /></div>
  </div>
</template>

<script>
export default {
    mounted: function() {
    },
    methods: {
        showKefuBox: function()
        {
            this.$store.commit("setKefuStatus",true);
        }
    }
}
</script>

<style>
.banner h3{ font-size: 36px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 18px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
.solution-bg{ background: url("../../assets/so_develop_f.png") right bottom no-repeat; height: 90%;}

.product{ height: 320px; position: relative; z-index: 9999;}
.product h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.product ul,.product ul li,.product ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block; line-height: 30px;}

.product ul li{ width: 19%; float: left; padding:40px 1px 1px 1px; text-align: center; height: 220px; padding-top: 40px; cursor: pointer; color: #000000; cursor: pointer;}
.product ul li .show_bg{ display: none;}
.product ul li.show{ border: 1px #eeeeee solid; padding:40px 0px 0px; border-radius: 5px; background-color: #ffffff; position: relative;}
.product ul li.show .show_bg{ height: 25px; background:url("../../assets/show_bg.png") bottom center no-repeat; position: absolute; bottom: -25px; width: 100%; display: block;}
.product ul li span i{ font-size: 45px;}
.productn ul li:hover{ color: #004172;}
.product ul li h3{ font-size: 18px; font-weight: normal;}
.product ul li p{ font-size: 14px;}

.product-item{ background-color: #edecec; padding: 50px 0px; position: relative; z-index: 888; margin-top: -30px;}
.product-item ul,.product-item ul li,.product-item ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block;}
.product-item ul{ margin-top: 50px; display: none;}
.product-item ul li{ width: 18%; float: left; margin-right: 20px; margin-bottom: 20px;}
.product-item ul li .img{ height: 250px; background-color: #ffffff; border-radius: 5px; overflow: hidden;}
.product-item ul li h3{ line-height: 30px; text-align: center; padding-top: 10px;}
.product-item ul.show { display: block;}

.aswer{ margin-bottom: 50px;}
.aswer h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.aswer .d{ font-size: 1rem;}
.aswer .form{ font-size: 1rem; float: left; width: 70%; padding-top: 50px;}
.aswer .form strong,.aswer .form span{ display: block; float: left; font-size: 0.9rem; font-weight: normal; margin-bottom: 10px;}
.aswer .form strong{ width: 10%; text-align: right; padding-top: 5px}
.aswer .form span{ width: 40%;;}
.aswer .form span input{ width: 80%; line-height: 30px; border: 1px #cccccc solid; border-radius: 5px; font-size: 1rem;}
.aswer .form span textarea{ border:1px #cccccc solid; width: 103%; border-radius: 5px;}
.aswer .form span button{ background-color: #036eb8; padding: 10px 30px; border: 1px #036eb8 solid; color: #ffffff; border-radius: 5px; font-size: 0.9rem; font-weight: normal; cursor: pointer;}
.aswer .qrcode{ float: right; width: 250px; border-left: 1px #eeeeee solid; text-align: center;}
.aswer .qrcode img{ margin-bottom: 5px; border: 1px #eeeeee solid; padding: 5px;}
.aswer .qrcode span{ display: block; line-height: 30px;}

.ads-index{ background-color: #e8e7e7; padding: 50px 0px; margin: 50px 0px;}
.ads-index h3,.ads-index p{ text-align: center; margin: 0px; padding: 0px;}
.ads-index h3{ font-size: 25px; font-weight: normal;}
.ads-index p{ font-size: 18px;}

.develop-index h3.t{ font-size: 25px; text-align: center; font-weight: normal; margin-top: 50px; margin-bottom: 0px; padding: 0px;}
.develop-index .d{ font-size: 18px; text-align: center; line-height: 30px; padding-bottom: 30px;}

.clients-index{ text-align: center; padding-bottom: 50px;}
</style>
